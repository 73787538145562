import React, {useEffect, useState} from 'react'
import { ToastContainer, toast } from "react-toastify";
//custom imports
import styles from '../../../assets/stylesheets/pages/project/project.module.scss'
import Table from '../../../uicomponents/table/Table'
import { getProjectList, getTotalProjects, getProjectData, updateProjectData, getSmsStats } from './service'
import useApi from '../../../hooks/useApi'
import Heading from '../../../uicomponents/heading'
import Tag from '../../../uicomponents/tag'
import Tab from '../../../uicomponents/tab'
import withTabGroup from '../../../hocs/withTabGroup'
import Popup from '../../../uicomponents/popup'
import SMSIntegration from './smsIntegration'
import TeleTask from './teleTask'
import ApiStats from './apiStats'
import DownloadReport from './downloadReport'
import {SmsIntegration, StatsIcon, DownloadIcon, EditIcon, PlayIcon, PauseIcon, CsvIcon, ApiIcon, PauseCalling, ResumeCalling} from '../../../assets/images'
import ThreeDotLoader from '../../../components/utilities/ThreeDotLoader';
import CustomTooltip from '../../../components/utilities/ToolTip'
import FrappButton from '../../../components/utilities/FrappButton';
import constants from '../../../util/constants';
import TruncateString from '../../../helper/TruncateString'
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import handleErrorMessage from '../../../helper/handleErrorMessage';
import moment from "moment";
import jwt_decode from "jwt-decode";

const TABS = [
    'Active',
    'Active-Stats',
    'Inactive'
]

const Projects = (props) => {
    const {state} = props && props.location
    const TabGroup = withTabGroup(Tab, 'group');
    const loggedInUser = localStorage && localStorage.authToken && 
    jwt_decode(localStorage.authToken)
    const getProjectListApi = useApi(getProjectList)
    const [activeTab, setActiveTab] = useState(state && state.activeTab ? state.activeTab : TABS[0])
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState(state && state.search ? state.search : '')
    const [currentPage, setCurrentPage] = useState(1);

    const [showSmsIntegration, setShowSmsIntegration] = useState(false)
    const [showTeleTask, setShowTeleTask] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const [showApi, setShowApi] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState('')
 
    const switchTab = (data) => {
        setActiveTab(data)
        setCurrentPage(1)
        setSearch('')
        filterChange('')
        setSort([])
    }

    useEffect(()=>{
        const skip = (currentPage - 1) * limit
        getProjectListApi.request(skip,limit,sort, search, activeTab.toLocaleLowerCase())
    },[currentPage, sort, search, activeTab])

    const goToprojectStatsPage = (data) => {
        props.history.push({
            pathname: `/projects/stats/${data.id}`,
            state: {
                projectdata:data,
                activeTab,
                search: search
            }
        })
    }

    const goToProjectSettingPage = (data) => {
        if(!data){ //new project creation 
                props.history.push({
                    pathname: `/create-project`,
                    state: {
                        formData: {
                            title: "",
                            description: "",
                            brand: "",
                            mainGoal: [""],
                            manager: "",
                            //  target: "fixed",
                            brandmanager: [],
                            teamlead: [],
                            //commitHours: 0,
                            reward: 0,
                            audioClip: "",
                            pauseHiring:true,
                            isDialout: false,
                            hasAllocatedDialing: false,
                            // faqs:[""],
                            scriptDocuments:[
                                {title:"Script",
                                url:""}
                            ],
                            audioFiles:[
                                {title:"Mock Audio",
                                url:""}
                            ],
                            trainingVideos:[{
                                title: '',
                                url: ''
                            }],
                            outcomes: constants.standardOutcomes,
                            languages: [
                                {
                                    name: 'English',
                                    default: true
                                }
                            ],
                            payout: "",
                            isWhitelisted: false
    
    
    
                        },
                        teamleads: [],
                        createProject: true, 
                        updateBtn: false, 
                        bm: false, brandSelected: "'", brandmanagers: []
    
                    }
                })
        }else
        props.history.push({
            pathname: `/update-project/${data.id}`,
            state: {
                formData: data,
                createProject: true,
                updateBtn: true,
                brandmanagers: [],
                teamleads: [],
                currentPage: currentPage
            }
        })
    }

    const toggleHiring = async(projectData) => {
        let projectDetails = ''
        const {id, pauseHiring} = projectData
        try{
            projectDetails = await getProjectData(id)
            delete projectDetails.updatedAt;
            delete projectDetails.createdAt;
            projectDetails.pauseHiring = !pauseHiring;
            if(projectDetails){
            getProjectListApi.setLoading(true)
            let isProjectUpdated = await updateProjectData(projectDetails, id)
            if(isProjectUpdated){
                data.map(item => {
                    if(item.id == id){
                        item.pauseHiring = projectDetails.pauseHiring
                        return item
                    }
                    return item
                })
                isProjectUpdated.data.pauseHiring ? toast("Hiring Paused") : toast("Hiring Resumed")
            }
            
            }
        }catch(err){
            if(err.response)
                toast((err.response && err.response.data)|| "Unexpected Error!")
            else
            toast(err.message || "Unexpected Error!")
        }finally{
            getProjectListApi.setLoading(false)
        }
    }

    const togglePauseCalling = async(projectData) => {
        let projectDetails = ''
        const {id, pauseCalling} = projectData
        try{
            projectDetails = await getProjectData(id)
            delete projectDetails.updatedAt;
            delete projectDetails.createdAt;
            projectDetails.pauseCalling = !pauseCalling;
            if(projectDetails){
                getProjectListApi.setLoading(true)
                let isProjectUpdated = await updateProjectData(projectDetails, id)
                if(isProjectUpdated){
                    data.map(item => {
                        if(item.id == id){
                            item.pauseCalling = projectDetails.pauseCalling
                            return item
                        }
                        return item
                    })
                    isProjectUpdated.data.pauseCalling ? toast("Calling Paused") : toast("Calling Resumed")
                }
            }
        }catch(err){
            if(err.response)
                toast((err.response && err.response.data)|| "Unexpected Error!")
            else
            toast(err.message || "Unexpected Error!")
        }finally{
            getProjectListApi.setLoading(false)
        }
    }

    const fetchSMSStats = async(projectData) => {
        let projectDetails = ''
        let smsStats = ''
        const {id} = projectData
        try{
            projectDetails = await getProjectData(id)
            smsStats = await getSmsStats(id)
            setSelectedRowData({...projectDetails, ...smsStats})
        }catch(err){
            if(err.response)
                toast((err.response && err.response.data) || "Unexpected Error!")
            else
            toast(err.message || "Unexpected Error!")
        }
    }

    const fetchTask = async(projectData) => {
        let projectDetails = ''
        const {id} = projectData
        try{
            projectDetails = await getProjectData(id)
            projectDetails = {
                ...projectDetails,
                allowDuplicateTasks: projectDetails.allowDuplicateTasks ? projectDetails.allowDuplicateTasks : false,
                taskProps: projectDetails.taskProps && projectDetails.taskProps.length > 0 ? projectDetails.taskProps : 
                [
                    { "name":"mobile"}
                ]
            }
            setSelectedRowData({...projectDetails})
        }catch(err){
            if(err.response)
                toast((err.response && err.response.data) || "Unexpected Error!")
            else
            toast(err.message || "Unexpected Error!")
        }finally{
            setShowTeleTask(true)
        }
    }

    const apiStats = async(projectData) => {
        let projectDetails = ''
        const {id} = projectData
        try{
            projectDetails = await getProjectData(id)
            projectDetails = {
                ...projectDetails,
                allowDuplicateTasks: projectDetails.allowDuplicateTasks ? projectDetails.allowDuplicateTasks : false,
                taskProps: projectDetails.taskProps && projectDetails.taskProps.length > 0 ? projectDetails.taskProps : 
                [
                    { "name":"mobile"}
                ]
            }
            setSelectedRowData({...projectDetails})
        }catch(err){
            if(err.response)
                toast((err.response && err.response.data) || "Unexpected Error!")
            else
            toast(err.message || "Unexpected Error!")
        }finally{
            setShowApi(true)
        }
    }

    const fetchReportData = async(projectData, type) => {
        let projectDetails = ''
        const {id} = projectData
        try{
            projectDetails = await getProjectData(id)
            projectDetails = {
                ...projectDetails,
                defaultStartDate:projectDetails.startDate.split('T')[0],
                defaultEndDate:projectDetails.endDate.split('T')[0],
                reportType: type
            }
            setSelectedRowData({...projectDetails})
        }catch(err){
            if(err.response)
                toast((err.response && err.response.data) || "Unexpected Error!")
            else
            toast(err.message || "Unexpected Error!")
        }finally{
            setShowReport(true)
        }
    }

    const columns = [
        {
            Header:'Project Id',
            accessor: "id",
            width: "10%",
            disableSortBy: true
        },
        {
            Header: 'Title',
            accessor: 'title',
            Cell: ({value}) => `${value || 'NA'}`,
            width: "15%",
            disableSortBy: true
        },
        {
            Header: 'SMS Integration',
            accessor: 'sms',
            Cell: ({cell}) => {
                return <div>
                <img src={SmsIntegration} alt='sms integration'
                className={[styles.sms_integration, cell.row.original.sms ? styles.active : styles.disabled].join(' ')}
                onClick={()=>{
                    if(cell.row.original.sms)
                    setShowSmsIntegration(true)
                    fetchSMSStats(cell.row.original)
                }}/>
                </div>
            },
            width: "2%",
            disableSortBy: true
        },
        {
            Header: 'Language',
            accessor: 'languages',
            Cell: ({value}) => {
                const languages = value && value.map(lang => lang.name)
                return <div>
                {languages &&  languages.length > 0 ? languages.toString() : 'NA'}
                </div>
            },
            width: "1%",
            disableSortBy: true
        },
        {
            Header: 'Type',
            Cell: ({cell}) => {
                const value = cell.row.original
                return ((value && value.hasAllocatedDialing) || (value && value.dialer)) 
                    ? <>{value.hasAllocatedDialing ? 'ALP' : 'Predictive'}</> : <>Default</>
                // return <Tag color={(((value && value.hasAllocatedDialing) || (value && value.dialer)) ) ? 'orange-tab-solid' : 'purple-tab-solid'}
                //  noShadow= {true}
                //  content={((value && value.hasAllocatedDialing) || (value && value.dialer)) 
                //     ? <>{value.hasAllocatedDialing ? 'ALP' : 'Predictive'}</> : <>Default</>}
                // />
            },
            width: "1%",
            disableSortBy: true
        },
        {
            Header: 'Task type',
            Cell:({cell}) => {
                return <div className={styles.tasktype_buttons}>
                    {checkIfUIExistsForRole(<Tag color={cell.row.original.owner ? 'orange-tab-solid' : 'purple-tab-solid'}
                        onClick={() => {
                            apiStats(cell.row.original)
                        }}
                        content={
                        <><img src={ApiIcon} alt='api-task'/> API</>
                        }
                    />)}
                    <Tag color={cell.row.original.owner ? 'orange-tab-solid' : 'purple-tab-solid'}
                        onClick={() => {
                           fetchTask(cell.row.original)
                        }} 
                        content={
                        <><img src={CsvIcon} alt='csv-task'/> CSV</>
                        }
                    />
                </div>
            },
            width: "10%",
            disableSortBy: true
        },
        {
            Header: 'Actions',
            accessor: "action",
            Cell: ({cell}) => {
                let pauseStatus = cell.row.original.pauseCalling
                return <div className={styles.action_row}>
                <CustomTooltip 
                placement='bottom'
                description='Download reports'
                component={
                    <DropdownButton
                    bsSize='small'
                    title={<img src={DownloadIcon} alt='download report'/>}
                    id="download"
                    >
                    <MenuItem eventKey="1" 
                    onSelect={() => fetchReportData(cell.row.original, 'Project Report')}
                    >Project Report</MenuItem>
                    <MenuItem eventKey="2" 
                    onSelect={() => fetchReportData(cell.row.original, 'Caller Report')}
                    >Caller Report</MenuItem>
                    <MenuItem eventKey="3" 
                    onSelect={() => fetchReportData(cell.row.original, 'Unprocessed Leads Report')}
                    >Unprocessed Report</MenuItem>
                    <MenuItem eventKey="4" 
                    onSelect={() => fetchReportData(cell.row.original, 'Live Leads Report')}
                    >Live Leads Report</MenuItem>
                    </DropdownButton>
                }/>
                {/* <CustomTooltip 
                placement='bottom'
                description={cell.row.original.pauseHiring ? 'Resume Hiring' : 'Pause Hiring'}
                component={
                <img src={cell.row.original.pauseHiring ? PlayIcon : PauseIcon} 
                alt={cell.row.original.pauseHiring ? 'hiring-off' : 'hiring-on'}
                onClick={()=> toggleHiring(cell.row.original)}/>
                }/> */}
                <CustomTooltip 
                    placement='bottom'
                    description= {pauseStatus ? 'Resume calling' : 'Pause calling'}
                    component={
                    <img 
                        src={pauseStatus ? ResumeCalling : PauseCalling} 
                        alt={pauseStatus ? 'Resume calling' : 'Pause calling'} 
                        onClick={()=>togglePauseCalling(cell.row.original)}
                    />
                }/>
                {checkIfUIExistsForRole(<CustomTooltip 
                    placement='bottom'
                    description='Edit project settings'
                    component={
                    <img src={EditIcon} alt='edit project' onClick={()=>goToProjectSettingPage(cell.row.original)}/>
                }/>)}
                </div>
            },
            width: "10%",
            disableSortBy: true
        },
        {
            Header: '',
            accessor: "stats",
            Cell: ({cell}) => {
                let pauseStatus = cell.row.original.pauseCalling
                return <div className={styles.action_row}>
                <CustomTooltip 
                placement = 'bottom'
                description = 'Project stats'
                component={
                <div className={styles.blue_link} 
                onClick={() => goToprojectStatsPage(cell.row.original)}
                >
                VIEW
                </div>
                }/>
                </div>
            },
            width: "2%",
            disableSortBy: true
        }
    ]

    const statsColumns = [
        {
            Header:'Project Id',
            accessor: "id",
            width: "15%",
            disableSortBy: true
        },
        {
            Header: 'Title',
            accessor: 'title',
            Cell: ({value}) => `${value || 'NA'}`,
            width: "20%",
            disableSortBy: true
        },
        {
            Header: 'Calls Database',
            accessor: "callsDatabase",
            Cell: ({value}) => ( value || 0),
            width: "7%"
        },
        {
            Header: 'Calls Made',
            accessor: "callsMade",
            Cell: ({value}) => ( value || 0),
            width: "7%"
        },
        {
            Header: 'Calls Completed',
            accessor: "callsConcluded",
            Cell: ({value}) => ( value || 0),
            width: "7%"
        },
        {
            Header: 'Selected Callers',
            accessor: "assignedCallers",
            Cell: ({value}) => ( value || 0),
            width: "7%"
        },
        {
            Header: 'Callers in Training',
            accessor: "trainingCallers",
            Cell: ({value}) => ( value || 0),
            width: "7%"
        },
        {
            Header: '',
            accessor: "stats",
            Cell: ({cell}) => {
                let pauseStatus = cell.row.original.pauseCalling
                return <div className={styles.action_row}>
                <CustomTooltip 
                placement = 'bottom'
                description = 'Project stats'
                component={
                <div className={styles.blue_link} 
                onClick={() => goToprojectStatsPage(cell.row.original)}
                >
                VIEW
                </div>
                }/>
                </div>
            },
            width: "5%",
            disableSortBy: true
        }
    ]

    const filterChange = (searchInput) => {
        setSearch(searchInput)
        setCurrentPage(1)
        window.history.replaceState({}, document.title)
    }

    const sortChange = (sortArray) => {
        if(sortArray !== '')
        setSort(sortArray)
        if(sortArray === '' && sort.length == 1)
        setSort([])
    }

    const renderSmsIntegration = () => {
        const sevenDaysAgo = moment().subtract(7,'days').startOf('day').toDate()
        return (
            <Popup
            show={showSmsIntegration}
            closePopup={()=>{
                setShowSmsIntegration(false)
                setSelectedRowData('')
            }}
            size='sm'
            heading={`SMS integration`}
            >
            <em>&#40;Showing live SMS stats from {sevenDaysAgo.toDateString()}&#41;</em><br/><br/>
            {selectedRowData ? <SMSIntegration data={selectedRowData}/> : <ThreeDotLoader/>}
            </Popup>
        )
    }

    const updateProjectDataProp = (updateData) => {
        setSelectedRowData({...selectedRowData, ...updateData})
    }

    const renderTeleTask = () => {
        return (
            <Popup
            show={showTeleTask}
            closePopup={()=>{
                setShowTeleTask(false)
                setSelectedRowData('')
            }}
            size='xlg'
            heading={`Task for ${TruncateString(selectedRowData.title, 120)}`}
             >
            <TeleTask data={selectedRowData} updateProjectData={updateProjectDataProp}/>
            </Popup>
        )
    }

    const renderApiStats = () => {
        return (
            <Popup
            show={showApi}
            closePopup={()=>{
                setShowApi(false)
                setSelectedRowData('')
            }}
            size='lg'
            heading={`Api stats for ${TruncateString(selectedRowData.title, 120)}`}
             >
            <ApiStats data={selectedRowData} updateProjectData={updateProjectData}/>
            </Popup>
        )
    }

    const renderDownloadReport = () => {
        return(
            <Popup
            show={showReport}
            closePopup={()=>{
                setShowReport(false)
                setSelectedRowData('')
            }}
            size='sm'
            heading={selectedRowData.reportType}
             >
            <DownloadReport data={selectedRowData} type={selectedRowData.reportType == 'Project Report' ? true : false}/>
            </Popup>
        )
    }

    const handlePopups = () => {
        if(showSmsIntegration) return renderSmsIntegration()
        if(showTeleTask) return renderTeleTask()
        if(showApi) return renderApiStats()
        if(showReport) return renderDownloadReport()
        else
        return null
    }

    const checkIfUIExistsForRole = (elem) => {
        return loggedInUser && loggedInUser.auth == 'team_lead' ? null : elem
    }

    let {data, total} = getProjectListApi.data || {}
    const {loading, error} = getProjectListApi

    useEffect(()=>{
        if(error)
        toast(handleErrorMessage(error))
    },[error])

    return(
        <div className={styles.page_wrapper}>
        <Heading text='Projects'/>
        <div className={styles.switch_row}>
            <TabGroup tabs={TABS} color='green-tab-outline' 
            onClick={switchTab} activeTab={activeTab}/>
            {(checkIfUIExistsForRole(<FrappButton className="submit" id="new-btn" handler={() => goToProjectSettingPage()}>Create</FrappButton>))}
        </div>
        <div>
            <Table
            columns={activeTab=="Active-Stats"?statsColumns:columns}
            data={data}
            totalCount={total}
            limit={limit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={loading}
            sortChange={sortChange}
            filterChange = {filterChange}
            searchBy='project id or title'
            isGlobalFilter = {true}
            search={search}
            />
        </div>
        {handlePopups()}
        <ToastContainer draggable={true} />
        </div>
    )
}

export default Projects