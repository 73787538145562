import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
// import { colourOptions } from "./docs/data";

const colourOptions = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9' },
    { value: 'blue', label: 'Blue', color: '#0052CC', disabled: true },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630' },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
  ];

const { MenuList, ValueContainer, SingleValue, Placeholder } = components;

const CustomMenuList = ({ selectProps, ...props }) => {
  const { onInputChange, inputValue, onMenuInputFocus, filterOptions } = selectProps;

  // Copied from source
  const ariaAttributes = {
    "aria-autocomplete": "list",
    "aria-label": selectProps["aria-label"],
    "aria-labelledby": selectProps["aria-labelledby"]
  };

  return (
    <div>
      <input
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding: 10,
          border: "none",
          borderBottom: "1px solid lightgrey"
        }}
        autoCorrect="off"
        autoComplete="off"
        spellCheck="false"
        type="text"
        value={inputValue}
        onChange={(e) =>{
          onInputChange(e.currentTarget.value, {
            action: "input-change"
          })
        }
        }
        // filterOptions={filterOptions}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.target.focus();
        }}
        onTouchEnd={(e) => {
          e.stopPropagation();
          e.target.focus();
        }}
        onFocus={onMenuInputFocus}
        placeholder="Search..."
        {...ariaAttributes}
      />
      <MenuList {...props} selectProps={selectProps} />
    </div>
  );
};

// Set custom `SingleValue` and `Placeholder` to keep them when searching
const CustomValueContainer = ({ children, selectProps, ...props }) => {
  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    setValue: props.setValue,
    selectProps,
    theme: props.theme
  };

  return (
    <ValueContainer {...props} selectProps={selectProps}>
      {React.Children.map(children, (child) => {
        return child ? (
          child
        ) : props.hasValue ? (
          <SingleValue
            {...commonProps}
            isFocused={selectProps.isFocused}
            isDisabled={selectProps.isDisabled}
          >
            {selectProps.getOptionLabel(props.getValue()[0])}
          </SingleValue>
        ) : (
          <Placeholder
            {...commonProps}
            key="placeholder"
            isDisabled={selectProps.isDisabled}
            data={props.getValue()}
          >
            {selectProps.placeholder}
          </Placeholder>
        );
      })}
    </ValueContainer>
  );
};

const SingleSelect = (props) => {
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const onDomClick = (e) => {
    let menu = containerRef.current.querySelector(".select__menu");

    if (
      !containerRef.current.contains(e.target) ||
      !menu ||
      !menu.contains(e.target)
    ) {
      setIsFocused(false);
      setInputValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);


  const filterOptions = (
    candidate,
    input
  ) => {
    if (input) {
    //   return candidate.value === customOptions[0].value;
    }
    return true;
  };

  const filterOption = (option, inputValue) => {
    const { label, value } = option;
    const otherKey = props.options.filter(
      opt => opt.label.toLowerCase().includes(inputValue.toLowerCase().trim())
    );
    if(otherKey.length <= 0){
        props.options = []
    }


    //  // Include all elements which includes the search query
    //  const updatedList = props.options.filter((item) => {
    //     return (
    //         item.label.toLowerCase().includes(inputValue.toLowerCase().trim()) ||
    //         item.value.toLowerCase().includes(inputValue.toLowerCase().trim())
    //       );
    // })

  };

  return (
    <div ref={containerRef}>
      <Select
        className="basic-single"
        classNamePrefix="select"
        name="color"
        options={props.options}
        components={{
          MenuList: CustomMenuList,
          ValueContainer: CustomValueContainer
        }}
        placeholder="Choose a project..."
        inputValue={inputValue}
        isSearchable={false}
        onMenuInputFocus={() => setIsFocused(true)}
        // filterOption={filterOption}
        onChange={(val) => {
            props.selectProject(val)
            setIsFocused(false)
        }}
        onInputChange={(val) => {
            setInputValue(val)
        }}
        {...{
          menuIsOpen: isFocused || undefined,
          isFocused: isFocused || undefined
        }}
      />
    </div>
  );
};

export default SingleSelect;