import React, { useState, useContext, useEffect } from 'react'
import Papa from 'papaparse';

import Dropdown from 'react-dropdown';
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import Upload from "../../../uicomponents/upload/upload";
import CSVSchema from './csvSchema';
import useApi from "../../../hooks/useApi";
import handleErrorMessage from "../../../helper/handleErrorMessage";
import ApiActions from '../../../actions/ApiActions';
import { BulkActionContext } from './bulkActionContext';
import FutworkButton from "../../../uicomponents/button/Button";
import ShowResult from "./showResult";
import ThreeDotLoader from '../../../components/utilities/ThreeDotLoader';
import Select, { components, defaultTheme } from 'react-select';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import SingleSelect from './activeProjects';

const { colors } = defaultTheme;

const MatchAgent = () => {
    const [file, setFile] = useState('')
    const [project, setProject] = useState({
        loading: true,
        data: []
    })
    const [inputValue, setInputValue] = useState({
        fileData: '',
        teleprojectId: ''
    })
    const [errorMsg, setErrorMsg] = useState({
        fileData: '',
        teleprojectId: '',
        api_error: ''
    })
    const [successMsg, setSuccessMsg] = useState({
        data: '',
        msg: '',
        failed: false
    })
    const { circularProg, checkComplete, isComplete, setIsComplete } = useContext(BulkActionContext)

    const bulkMatchAgentsApi = useApi(ApiActions.bulkMatchAgents)

    const { data, loading, error } = bulkMatchAgentsApi || {}


    const fetchProjects = async () => {
        try {
            let result = await ApiActions.getAvailableJobs()
            setProject({
                loading: false,
                data: result.map(item => {
                    return {
                        label: item.title,
                        value: item._id
                    }
                })
            })
        } catch (error) {
            console.error(error)
            setProject({
                loading: false,
                data: []
            })
        }
    }

    useEffect(() => {
        fetchProjects()
    }, [])

    useEffect(() => {
        setErrorMsg({
            ...errorMsg,
            api_error: handleErrorMessage(error)
        })
    }, [error])

    useEffect(() => {
        if (loading == false && (data && data.data)) {
            setIsComplete(2)
            if (data && data.data && data.data.unprocessed && data.data.unprocessed.length > 0) {
                setSuccessMsg({
                    msg: `Failed to match ${data && data.data && data.data.unprocessed.length} agent${data.data.unprocessed.length > 1 ? `'s` : ''} for the project`,
                    data: data && data.data, failed: true
                })
            } else {
                setSuccessMsg({ msg: `${data && data.data && data.data.processed.length} agent${data.data.processed.length > 1 ? `'s` : ''} are matched to the project`, data: data && data.data })
                setTimeout(() => {
                    circularProg.current.classList.toggle(styles['load_complete'])
                    checkComplete.current.style.display = 'block'
                }, 1000);
            }
        }
    }, [loading, data])


    const resetData = () => {
        setErrorMsg({ fileData: '', api_error: '', teleprojectId: '' })
        setSuccessMsg({ data: '', msg: '', failed: false })
        setInputValue({ fileData: '', teleprojectId: '' })
    }

    //call the function on choose the file
    const changeHandler = (e) => {
        const file = e.target.files[0]
        if (file && file.name && file.name.match(/\.(csv)$/)) { //check if the attached file is csv
            if (e.target.files.length > 0) {
                setFile(file)
                Papa.parse(file, {
                    header: true,
                    complete: function (results) {
                        const sanitizedResult = results.data.map(obj => {
                            if (obj.mobile) {
                                return obj
                            }
                        }).filter(Boolean)
                        setInputValue({
                            ...inputValue,
                            fileData: sanitizedResult
                        })
                    }
                }
                )
            }
            const temp = { ...errorMsg }
            temp.fileData = ''
            setErrorMsg(temp)
        } else {
            setFile('')
            const temp = { ...errorMsg }
            temp.fileData = 'Only a file with .csv extension can be uploaded'
            setErrorMsg(temp)
        }
    }

    //check if a file is attached
    const checkIfDataPresent = () => {
        const { fileData } = inputValue
        if (fileData) return false
        else return true
    }

    //validate the data  in csv
    const checkIfValid = (csvheaders) => {
        let validationErrors = {};
        const multipleMandatoryHeaders = []
        csvheaders && csvheaders.forEach(item => {
            if (item.toLowerCase() == 'mobile') multipleMandatoryHeaders.push(item)
        });

        // Simple validation for empty fields
        for (const key in inputValue) {
            // if (key == 'wa_from' && (inputValue[key].length < 10 || !isNumeric(inputValue[key]))) {
            //     validationErrors[key] = 'Please enter a valid 10 digit mobile number'
            // }
            // if (key == 'fileData' && inputValue[key].length > 5000) {
            //     validationErrors[key] = 'Please make sure the CSV contains less than 5000 rows of data.'
            // }
            // if (key == 'fileData' && csvheaders.find(head => (head.toLowerCase() == 'earnings' && head.toLowerCase() == 'mobile')) == undefined) {
            //     // validationErrors[key] = 'Please ensure that the csv includes the earnings & mobile.'
            // }
            if (key == 'fileData' && multipleMandatoryHeaders && multipleMandatoryHeaders.length < 1) {
                validationErrors[key] = `Please ensure that "mobile" is mentioned in the csv schema`
            }
        }
        if (Object.keys(validationErrors).length > 0) {
            setErrorMsg(validationErrors);
            return false;
        } else {
            return true
        }
    }

    //upload the csv with for corresponding agents
    const triggerMatchAgents = () => {
        const [first] = inputValue.fileData
        const body_vars = first ? Object.keys(first).map(item => item) : []
        if (checkIfValid(body_vars)) {
            const formData = new FormData();
            // formData.append('teleprojectId', projectData.id);
            formData.append('file', file);
            formData.append('teleprojectId', inputValue.teleprojectId)
            bulkMatchAgentsApi.request(formData)
            setIsComplete(1)
        }
    }

    const props = {
        resetData,
        errorMsg,
        loading,
        successMsg
    }

    const selectStyles = {
        control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
        menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
    };

    const Svg = (p) => (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          focusable="false"
          role="presentation"
          {...p}
        />
    );

    const DropdownIndicator = () => (
        <div css={{ color: colors.neutral20, height: 2, width: 32 }}>
          <Svg>
            <path
              d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </Svg>
        </div>
      );

    const selectProject = (option) => {
        setInputValue({
            teleprojectId: option.value
        })
    }

    return project.loading ? <ThreeDotLoader /> : isComplete <= 0 ? <div>
        <div className={styles.crud_box}>
            <div className={styles.crud_form}>
                <div className={styles.form_label}>Select Project</div>
                <SingleSelect options={project.data} selectProject={selectProject}/>
                {/* <DropdownButton
                disabled={false}
                title={inputValue.teleproject ? inputValue.teleproject : `Choose a project`}
                >
                    <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{
                    // Input: CustomInput,
                    DropdownIndicator,
                    IndicatorSeparator: null,
                    }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen
                    onChange={(selectedOption) => {
                        setInputValue({
                            ...inputValue,
                            teleprojectId: selectedOption.value,
                            // teleproject: selectedOption.label
                        })
                        // setSelectedHeaders(selectedOptions);
                    }}
                    options={project.data}
                    placeholder="Search..."
                    className={styles['react-select-container']}
                    classNamePrefix="react-select"
                    styles={selectStyles}
                    tabSelectsValue={false}
                    // value={selectedHeaders}
                    isDisabled={false} // Disable dropdown if no headers
                    isSearchable={true} // Disable search input if no headers
                    />
                </DropdownButton> */}
                {/* <Dropdown
                    options={project.data}
                    onChange={(e) => {
                        setInputValue({
                            ...inputValue,
                            teleprojectId: e.value
                        })
                        // setCurrentPage(1)
                        // setSelectOutcome(e.value == 'All outcomes' ? '' : e.value)
                        // setCurrentAction(e.value)
                    }}
                    // value={selectOutcome == '' ? 'All outcomes' : selectOutcome}
                    placeholder="Please choose a project" /> */}
            </div>
        </div>

        <div className={styles.crud_box}>
            <div className={styles.crud_form}>
                <CSVSchema />
                <Upload placeholder='Select CSV file to upload' onChange={changeHandler} />
                {
                    errorMsg && errorMsg.fileData ? <span className={styles.csv_error}>{errorMsg.fileData}</span> : null
                }
            </div>
        </div>
        <div className={styles['text-center']}>
            <FutworkButton buttonStyle="primary--solid" buttonSize="medium"
                disabled={checkIfDataPresent()}
                onClick={triggerMatchAgents}>SUBMIT</FutworkButton>
        </div>
    </div> :
        <div className={styles.crud_box}>
            <ShowResult {...props} />
        </div>
}

export default MatchAgent