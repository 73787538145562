import constants from "../util/constants";
import jwt_decode from "jwt-decode";
import React from 'react'

import {
    HiringRouter,
    ProjectRouter,
    ToolRouter,
    CallerRouter,
    ScriptBuilderRouter,
    AuditRouter,
    ModerationRouter
} from '../routes'

import Welcome from "../components/pages/Welcome";
import TringDashboard from "../components/pages/TringDashboard";
import SupportApps from '../components/containers/SupportApps';
import TringBrandsScreen from '../components/pages/TringBrandsScreen'
import CreateTeleProject from '../components/pages/CreateTeleProject';
import TringModeration from '../components/pages/TringModeration'
import MatchViaMobile from '../pages/caller/roaster/matchViaMobile'
import AddQuiz from '../components/pages/AddQuiz';
import MockTest from '../components/pages/MockTest';
import DefineLeads from '../components/pages/DefineLeads';
import ProjectQc from '../components/pages/ProjectQc';
import SmsTemplate from '../components/pages/SmsTemplate'
import Communication from "../components/pages/Communication";
import Templates from "../pages/project/projectconfiguration/templates";
import CallAudit from '../components/pages/CallAudit';
import CallerAudit from '../components/pages/CallerAudit';
import CallersIQC from '../components/pages/CallersIQC';
import AccountCreation from '../components/pages/Tools/AccountCreation'
import WildCardTool from '../components/pages/Tools/WildCardTool'
import QuizFinal from '../components/pages/Tools/QuizFinal'
import DashboardGateway from '../components/pages/Tools/DashboardGateway'
import CallDialer from '../pages/tools/callDialer'
import ExophoneManagement from "../pages/tools/exophone-management";
import ExotelAccountSetup from '../components/pages/Tools/ExotelAccountSetup'
import ExophoneProjectLink from '../pages/tools/exophoneProjectLink'
import DTDCLeadsUnmasking from '../pages/tools/dtdcLeadsUnmask'
import CreateQuiz from '../components/pages/CreateQuiz';
import { HomeLogo } from "../assets/images";
import WhatsappBulkSend from "../pages/tools/whatsappBulkSend";
import AgentMetadataManagement from "../pages/tools/agent-metadata-management";

export default [
    {
        component: Welcome,
        path: '/home',
        title: <img src={HomeLogo} width='30px'/>,
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.COACH,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.CONTENT_MANAGER,
            constants.customRole.TMP,
            constants.customRole.SUPPORT,
            constants.customRole.QA_AGENT,
            constants.customRole.TEAM_LEAD
        ]
    },
    {
        component: TringDashboard,
        path: '/stats',
        title: 'Stats',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.COACH,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.CONTENT_MANAGER,
            constants.customRole.TMP,
            constants.customRole.SUPPORT,
            constants.customRole.QA_AGENT,
            constants.customRole.TEAM_LEAD
        ]
    },
    {
        component: SupportApps,
        path: '/support-apps',
        title: 'Support Apps',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.SUPPORT,
        ]
    },
    {
        component: ProjectRouter,
        path: '/projects',
        title: 'Project Management',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.CONTENT_MANAGER,
            constants.customRole.TEAM_LEAD
        ],
        children: [
            {
                path: '/projects',
                title: 'Projects',
                permission: [
                    constants.customRole.ADMIN,
                    constants.customRole.PROJECT_MANAGER,
                    constants.customRole.CONTENT_MANAGER,
                    constants.customRole.TEAM_LEAD
                ]
            },
            {
                path: '/brands',
                title: 'Brands',
                permission: [
                    constants.customRole.ADMIN,
                    constants.customRole.PROJECT_MANAGER,
                    constants.customRole.CONTENT_MANAGER
                ]
            }
        ]
    },
    {
        component: TringBrandsScreen,
        path: '/brands',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.CONTENT_MANAGER
        ]
    },
    {
        component: CreateTeleProject,
        path: '/create-project',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.CONTENT_MANAGER
        ]
    },
    {
        component: CreateTeleProject,
        path: '/update-project/:id',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.CONTENT_MANAGER
        ]
    },
    {
        component: CreateQuiz,
        path: '/create-quiz',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.CONTENT_MANAGER
        ]
    },
    {
        component: CreateQuiz,
        path: '/update-quiz/:id',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.CONTENT_MANAGER
        ]
    },
    {
        component: AddQuiz,
        path: '/add-quiz/:id',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.CONTENT_MANAGER
        ]
    },
    {
        component: MockTest,
        path: '/mock-test/:id',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.CONTENT_MANAGER
        ]
    },
    {
        component: DefineLeads,
        path: '/define-leads/:id',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.CONTENT_MANAGER
        ]
    },
    {
        component: ProjectQc,
        path: '/project-qc/:id',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.CONTENT_MANAGER
        ]
    },
    {
        component: Communication,
        path: '/communication-template/:id',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.CONTENT_MANAGER
        ]
    },
    {
        component: CallerRouter,
        path: '/caller',
        title: 'Caller Management',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.TMP
        ],
        children: [
            {
                path: '/caller',
                title: 'Onboarding'
            },
            {
                path: '/caller/payment',
                title: 'Payments'
            },
            {
                path: '/caller/attendance-tracker',
                title: 'Attendance Tracker'
            },
            {
                path: '/caller/bulk-action',
                title: 'Bulk Actions'
            }
        ]
    },
    {
        component: TringModeration,
        path: '/tring-moderation',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.TMP
        ]
    },
    {
        component: MatchViaMobile,
        path: '/match-via-mobile',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.TMP
        ]
    },
    {
        component: HiringRouter,
        path: '/hiring',
        title: 'Hiring',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.COACH,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.TEAM_LEAD
        ],
        children: [
            {
                path: '/hiring/dashboard',
                title: 'Hiring Dashboard',
                // permission: [
                //     constants.customRole.COACH
                // ]
            },
            {
                path: '/hiring',
                title: 'Hiring Management',
                // permission: [
                //     constants.customRole.COACH
                // ]
            }
        ]
    },
    {
        component: AuditRouter,
        path: '/audit',
        title: 'Quality Audit',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.QA_AGENT
        ],
        children: [
            {
                path: '/audit/qmf',
                title: 'Quality Audits (legacy)'
            },
            {
                path: '/audit/qa-compliance',
                title: 'QA - Compliance'
            },
            {
                path: '/audit/v2',
                title: 'QMF (New)'
            }
        ]
    },
    {
        component: CallAudit,
        path: '/call-audit/:id',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.QA_AGENT
        ]
    },
    {
        component: CallerAudit,
        path: '/caller-audit/:id',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.QA_AGENT
        ]
    },
    {
        component: CallersIQC,
        path: '/caller-iqc/:id',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.QA_AGENT
        ]
    },
    {
        component: ScriptBuilderRouter,
        path: '/interactive-script',
        title: 'ScriptBuilder',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.CONTENT_MANAGER
        ]
    },
    {
        component: ToolRouter,
        path: '/tools',
        title: 'Tools',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.COACH,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.CONTENT_MANAGER,
            constants.customRole.TMP,
            constants.customRole.SUPPORT,
            constants.customRole.QA_AGENT,
            constants.customRole.TEAM_LEAD
        ],
        isMenu: false,
        children: [
            {
                component: QuizFinal,
                path: '/tools/quiz',
                permission: [
                    constants.customRole.ADMIN,
                    constants.customRole.CONTENT_MANAGER
                ]
            },
            {
                component: AccountCreation,
                path: '/tools/account-creation',
                permission: [
                    constants.customRole.ADMIN
                ]
            },
            {
                component: DashboardGateway,
                path: '/tools/dashboard-gateway',
                permission: [
                    constants.customRole.ADMIN
                ]
            },
            {
                component: ExotelAccountSetup,
                path: '/tools/exotel-account',
                permission: [
                    constants.customRole.ADMIN
                ]
            },
            {
                component: ExophoneManagement,
                path: '/tools/exotel-account-new',
                permission: [
                    constants.customRole.ADMIN,
                    constants.customRole.PROJECT_MANAGER
                ]
            },
            {
                component: WildCardTool,
                path: '/tools/wildcard',
                permission: [
                    constants.customRole.ADMIN
                ]
            },
            {
                component: CallDialer,
                path: '/tools/call-dialer',
                permission: [
                    constants.customRole.ADMIN,
                    constants.customRole.COACH,
                    constants.customRole.PROJECT_MANAGER,
                    constants.customRole.CONTENT_MANAGER,
                    constants.customRole.TMP,
                    constants.customRole.SUPPORT,
                    constants.customRole.QA_AGENT,
                    constants.customRole.TEAM_LEAD
                ]
            },
            {
                component: ExophoneProjectLink,
                path: '/tools/exophone-project-link',
                permission: [
                    constants.customRole.ADMIN,
                    constants.customRole.PROJECT_MANAGER
                ]
            },
            {
                component: DTDCLeadsUnmasking,
                path: '/tools/dtdc-leads-unmasking',
                permission: [
                    constants.customRole.ADMIN,
                    constants.customRole.PROJECT_MANAGER,
                    constants.customRole.CONTENT_MANAGER
                ]
            },
            {
                component: WhatsappBulkSend,
                path: '/tools/whatsapp-on-demand-campaign',
                permission: [
                    constants.customRole.ADMIN,
                    constants.customRole.CONTENT_MANAGER,
                    constants.customRole.COACH,
                    constants.customRole.TMP,
                    constants.customRole.PROJECT_MANAGER
                ]
            },
            {
                component: AgentMetadataManagement,
                path: '/tools/agent-metadata-tool',
                permission: [
                    constants.customRole.ADMIN,
                    constants.customRole.PROJECT_MANAGER
                ]
            }
        ]
    },
    {
        component: ModerationRouter,
        path: '/moderation',
        title: 'Moderation',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.PROJECT_MANAGER
        ],
        children: [
            {
                path: '/moderation/brand',
                title: 'Brand'
            },
            {
                path: '/moderation/wallet',
                title: 'Wallet'
            }
        ]
    },
    {
        component: '',
        path: '',
        title: 'Profile',
        permission: [
            constants.customRole.ADMIN,
            constants.customRole.COACH,
            constants.customRole.PROJECT_MANAGER,
            constants.customRole.CONTENT_MANAGER,
            constants.customRole.TMP,
            constants.customRole.SUPPORT,
            constants.customRole.QA_AGENT,
            constants.customRole.TEAM_LEAD
        ],
        children: [
            {
                component: '',
                path: '',
                title: localStorage.userName ? localStorage.userName: null
            },
            {
                component: '',
                path: '',
                title: localStorage.authToken ? `Role: ${jwt_decode(localStorage.authToken).auth}` : ''
            },
            {
                component: '',
                path: '',
                title: `Logout`
            }
        ]
    }
]