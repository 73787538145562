import React, { useContext } from 'react'
import { CSVLink } from "react-csv";

//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import { DownloadIcon } from '../../../assets/images';
import { BulkActionContext } from './bulkActionContext';
import CustomTooltip from '../../../components/utilities/ToolTip';

const CSVSchema = () => {
    const defaultSchema = [{ "mobile": "" }]
    const { currentAction, OFFER_LETTER, ADD_BASE_PAY, CERTIFY_AGENT, MATCH_AGENT } = useContext(BulkActionContext)
    let fileName = ''
    const getSchema = () => {
        switch (currentAction.value) {
            case OFFER_LETTER:
                fileName = 'onboarding_upload_offer_letter_for_agents'
                return [...defaultSchema, { "url": "" }]
            case ADD_BASE_PAY:
                fileName = 'onboarding_upload_earnings_for_agents'
                return [...defaultSchema, { "earnings": "" }]
            case CERTIFY_AGENT:
                fileName = 'onboarding_upload_agents_to_certify'
                return defaultSchema
            case MATCH_AGENT:
                fileName = 'onboarding_upload_agents_to_match'
                return defaultSchema
            default:
                return defaultSchema
        }
    }
    const tableHeaders = [...getSchema()].map(item => {
        item = { name: Object.keys(item).toString(), mandatory: true }
        return item
    })

    return (
        <div className={styles.display_schema}>
            <div className={styles.form_label}>The CSV should have the defined schema</div>
            <table className={styles.headers_table}>
                <tr>
                    {
                        tableHeaders.map(header => {
                            return <th className={styles.headers_list}>{header.name}{header.mandatory ? <span className={styles.mandatory}>*</span> : null}</th>
                        })
                    }
                </tr>
            </table>
            <CSVLink data={getSchema()}
                filename={`${fileName}.csv`}
            >
                <div className={styles.download_btn}>
                    <CustomTooltip placement={'bottom'} component={<img title="Download Template" src={DownloadIcon} alt='download' />}
                        description={'Download the schema as csv'} />
                </div>
            </CSVLink>
        </div>
    )
}

export default CSVSchema